<template>
    <div class="login-box">
        <div class="login-logo">
            <img src="@/assets/logo.svg" alt="ANASAP Logo">
        </div>
        <!-- /.login-logo -->
        <div class="login-box-body">
            <p class="login-box-msg">Byl Vám zaslán ověřovací email.</p>
            <p class="login-box-msg">Do několika minut by měl přijít a jeho platnost je 24 hodin od teď. Po ověření budete mít zpřístupněnou aplikaci.</p>
            <form>
            <div class="row">
                <!-- <div class="col-xs-6">
                <button type="button" class="btn btn-primary btn-block btn-flat">Zaslat znovu</button>
                </div> -->
                <div class="col-12">
                <a href="/#/login"><button type="button" class="ml-auto anasap-btn">Přihlásit</button></a>
                </div>
                <!-- /.col -->
            </div>
            </form>

        </div>
        <!-- /.login-box-body -->
    </div>
</template>

<script>
const name = 'SentVerificationEmail';

export default {
    name
}
</script>

<style>
    @import "../assets/scss/login.scss";

    .row {
        margin: 0;
    }
</style>
